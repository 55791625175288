import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { apiRoute } from "../helpers";
import { viewerRoute } from "../helpers";
import { getSelectedImage } from '../store/actions/images/current';
import { Spinner } from "react-bootstrap";
import {
  faExternalLinkSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilmStrip = ({ onSelectImage, currentDatasetIndex, onLaunchViewer  }) => {
  const dispatch = useDispatch();
  const nextImage = useSelector((state) => state.next.next);
  const prevImage = useSelector((state) => state.previous.previous);
  const selectedImage = useSelector((state) => state.images?.currentImage);
  const selectedRectangles = useSelector(
    (state) => state.pathologies.pathologies
  )

  const scrollContainerRef = useRef(null);

  const [selectedImageSetType, setSelectedImageSetType] = useState('All');

  const [isLoadingMap, setIsLoadingMap] = useState({});

  const selectedProjectId = useSelector((state => state.projects.selectedProjectId));

  const pathologyOptions = useSelector(
    (state) => state.selectionOptions.options.pathologies
  );

  useEffect(() =>{
    // console.log('url req', selectedProjectId, selectedImage )
  }, [selectedProjectId, selectedImage])

  var images = [];

  if (nextImage && nextImage.image) {
    // Check if otherImages is iterable
    const otherImages = Array.isArray(nextImage.image.otherImages) ? nextImage.image.otherImages : [];
  
    if (nextImage?.image?._id === (selectedImage?._id || otherImages.findIndex(oi => oi._id === selectedImage?._id) >= 0)) {
      images = [nextImage.image, ...otherImages];
    } else {
      if (prevImage) {
        var tPrev = prevImage.find(p => p.image?._id === selectedImage?._id);
        if (!tPrev) {
          for (var i = 0; i < prevImage.length; i++) {
            if (prevImage[i].image && Array.isArray(prevImage[i]?.image?.otherImages) && prevImage[i]?.image?.otherImages.findIndex(oi => oi._id === selectedImage?._id) >= 0) {
              tPrev = prevImage[i];
            }
          }
        }
        if (tPrev) {
          const tOther = Array.isArray(tPrev.image.otherImages) ? tPrev.image.otherImages : [];
          var currentImage = JSON.parse(JSON.stringify(tPrev.image));
          currentImage.otherImages = null;
          images = [currentImage, ...tOther];
        }
      }
    }
  }

  const imagesFromSameSet = images.filter(img => img.datasetId === selectedImage.datasetId || img._id === selectedImage._id);

  // // console.log('sets images', imagesFromSameSet);
  // // console.log('sets images 2', images);

  const [selectedImageId, setSelectedImageId] = useState('');

  let imageSetTypes = images.map(img => img.image_set?.window).filter(Boolean);
  imageSetTypes = ['All', ...new Set(imageSetTypes)];

  const filteredImages = selectedImageSetType === 'All' ? images : images.filter(img => img.image_set.window === selectedImageSetType);

  useEffect(() => {
    if (imagesFromSameSet && imagesFromSameSet.length > 0 && selectedImageId === null) {
      const firstImageId = imagesFromSameSet[0]._id;
      setSelectedImageId(firstImageId);
    }
  }, [imagesFromSameSet, selectedImageId, dispatch]);

  useEffect(() => {
    if (currentDatasetIndex === undefined) return null;
    if (imagesFromSameSet.length > 0 && currentDatasetIndex !== undefined) {
      const newSelectedImageId = imagesFromSameSet[currentDatasetIndex]?._id;
      if (newSelectedImageId) {
        setSelectedImageId(newSelectedImageId);
        dispatch(getSelectedImage(newSelectedImageId));
      }
    }
  }, [currentDatasetIndex]);

  useEffect(() => {
    if (imagesFromSameSet.length > 0 && !selectedImageId) {
      const initialImageId = imagesFromSameSet[currentDatasetIndex || 0]._id;
      setSelectedImageId(initialImageId);
      dispatch(getSelectedImage(initialImageId));
    }
  }, [imagesFromSameSet, currentDatasetIndex, dispatch]);

//   useEffect(() => {
//     // This checks if there is a need to update the selected image based on the dataset index
//     // and updates only if the index or the set of images changes.
//     if (currentDatasetIndex !== undefined && imagesFromSameSet.length > 0) {
//         const newSelectedImageId = imagesFromSameSet[currentDatasetIndex]?._id;
//         if (newSelectedImageId && newSelectedImageId !== selectedImageId) {
//             setSelectedImageId(newSelectedImageId);
//             dispatch(getSelectedImage(newSelectedImageId));
//         }
//     } else if (imagesFromSameSet.length > 0 && !selectedImageId) {
//         // This case handles initialization or resetting of the selectedImageId
//         const initialImageId = imagesFromSameSet[0]._id;
//         setSelectedImageId(initialImageId);
//         dispatch(getSelectedImage(initialImageId));
//     }
// }, [currentDatasetIndex, imagesFromSameSet, selectedImageId, dispatch]);

const renderImageSetTypeDropdown = () => {
  if (imageSetTypes.length > 1) {
    return (
      <div className="dropdown-select" style={{ width: '200px' }}>
        <select
          value={selectedImageSetType}
          onChange={(e) => setSelectedImageSetType(e.target.value)}
          className="mb-6 px-1 dropdown-toggle btn btn-primary"
          style={{
            marginLeft: '10px',
            width: '100%',
            maxHeight: '100px', // Set the maximum height
            overflowY: 'auto', // Enable vertical scrolling
          }}
        >
          {imageSetTypes.map((type, index) => (
            <option key={index} value={type} style={{ backgroundColor: 'white', color: 'black' }}>
              {type}
            </option>
          ))}
        </select>
      </div>
    );
  }
  return null;
};

  const handleThumbnailClick = (imageId, index) => {
    setIsLoadingMap({ ...isLoadingMap, [imageId]: true });
    dispatch(getSelectedImage(imageId));
    setSelectedImageId(imageId);
    onSelectImage(index);
    setTimeout(() => {
      setIsLoadingMap({ ...isLoadingMap, [imageId]: false });
    }, 3000);
  };

  const hasPathology = (imageId) => {
    return selectedRectangles.some(rect => rect.imgId === imageId);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (imagesFromSameSet && imagesFromSameSet.length > 0) {
        let newIndex = currentDatasetIndex;
        if (event.key === 'ArrowRight') {
          newIndex = (currentDatasetIndex + 1) % imagesFromSameSet.length;
        } else if (event.key === 'ArrowLeft') {
          newIndex = (currentDatasetIndex - 1 + imagesFromSameSet.length) % imagesFromSameSet.length;
        }
        if (newIndex !== currentDatasetIndex) {
          const newSelectedImageId = imagesFromSameSet[newIndex]?._id;
          if (newSelectedImageId) {
            setSelectedImageId(newSelectedImageId);
            onSelectImage(newIndex); 
            dispatch(getSelectedImage(newSelectedImageId));
          }
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentDatasetIndex, imagesFromSameSet, onSelectImage, dispatch]);

  useEffect(() => {
    if (selectedImageId && scrollContainerRef.current) {
      const selectedThumbnail = scrollContainerRef.current.querySelector(`.image-thumbnail[data-image-id="${selectedImageId}"]`);
      if (selectedThumbnail) {
        selectedThumbnail.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [selectedImageId]);

  const token = localStorage.getItem("token");

  const handleClick = () => {
    if (selectedImage && selectedProjectId) {
      const url = `${viewerRoute()}?imgId=${encodeURIComponent(selectedImage._id)}&projectId=${encodeURIComponent(selectedProjectId)}&window=${encodeURIComponent(selectedImage.image_set?.window || '')}&token=${encodeURIComponent(token)}`;
      window.open(url, '_blank', 'noopener,noreferrer');
      // // console.log('clicked', url);
      // // console.log('window', selectedImage.image_set)
      // // console.log('token', token)
      onLaunchViewer(new Date().toISOString()); // Start polling when the 3D Viewer is launched
    } else {
      console.error('Selected image or project is missing');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', color: "#fff", margin: "8px 0 6px 20px", paddingTop: '10px' }}>
        <h6 style={{ color:'#fff' }}>Image Set</h6>
        <div>
            {/* <button 
              onClick={handleClick} 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                padding: '6px', 
                fontSize: '18px', 
                cursor: 'pointer', 
                borderRadius: '50%', 
                border: 'none', 
                backgroundColor: '#007bff', 
                color: '#fff',
                margin: '0 0 4px 10px'
              }}
              aria-label="Open new window"
              title='Launch 3d Viewer'
            >
              <FontAwesomeIcon icon={faExternalLinkSquare} />
            </button> */}
            </div>
        {renderImageSetTypeDropdown()}
      </div>
      <div 
        ref={scrollContainerRef}
        className="image-scroll"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start', 
          gap: '10px', 
          padding: '10px', 
          margin: "8px 20px 6px 20px",
          overflowX: 'auto',
          scrollBehavior: 'smooth'
        }}>
        {filteredImages && filteredImages.map((img, index) => (
          <div 
            key={index}
            className='image-container'
            style={{ 
              minWidth: 'calc(25% - 10px)',
              maxWidth: 'calc(25% - 10px)',
            }}
            >
            <div
              className='image-thumbnail'
              style={{ 
                position: 'relative', 
                backgroundImage: `url(${apiRoute()}/image/${img._id}?thumb=yes)`,
                width: '100%', 
                height: '100px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                border: hasPathology(img._id) ? '2px solid orange' : '2px solid #ccc',
                cursor: 'pointer'
              }}
              onClick={() => handleThumbnailClick(img._id, index)}
            >
              <input 
                type="checkbox" 
                checked={selectedImageId === img._id} 
                onChange={() => handleThumbnailClick(img._id, index)} 
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '40px',
                  cursor: 'pointer'
                }}
                className="image-checkbox"
              />
              {isLoadingMap[img._id] && 
                <div className="loader" style={{ position: 'absolute', bottom: '50%', right: '50%', transform: 'translate(50%, 50%)' }}>
                  <Spinner 
                    animation="border" 
                    variant="custom" 
                    size="md" 
                    className="mr-2"
                    style={{ cursor: 'pointer' }} 
                  />
                </div>
              }
            </div>
            <div style={{ textAlign: 'center', marginTop: '5px', color: '#fff', fontSize: '14px', overflowWrap: 'break-word' }}>
              <span 
                className="badge p-1 bg-yellow color-black font-weight-400 badge-secondary badge-pill"
                style={{ 
                  wordWrap: 'break-word', // Allows long words to be broken and wrap to the next line
                  whiteSpace: 'normal'    // Ensures the text wraps normally
                }}
              >{img.image_set.identifier} - {img.image_set.window ? img.image_set.window : index + 1}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FilmStrip;